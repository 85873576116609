import { defineStore } from 'pinia';
import { signInWithEmailAndPassword, User } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import ClientAppError from 'public/modules/clientAppError';
import { auth } from '../modules/firebase/firebaseInit';
import { handleError } from '../modules/errorHandler';

type UserRole = 'MIRENCO_ADMIN' | 'USER' | null;

type UserState = {
  isLoggedIn: boolean;
  userName: string | null;
  userEmail: string | null;
  userRole: UserRole;
  loading: boolean;
}

// Map Firebase auth error codes to user-friendly messages
const AUTH_ERROR_MESSAGES: Record<string, string> = {
  'auth/too-many-requests': 'Too many requests. Try again later.',
  'auth/invalid-credential': 'Invalid username or password.',
  'auth/user-disabled': 'This account has been disabled.',
  'auth/user-not-found': 'No account found with this email address.',
  'auth/wrong-password': 'Invalid username or password.',
  'auth/invalid-email': 'Invalid email format.',
  'auth/network-request-failed': 'Network error. Please check your connection.',
};

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore({
  id: 'user',
  state: ():UserState => ({
    isLoggedIn: false,
    userName: null,
    userEmail: null,
    userRole: null,
    loading: false,
  }),
  actions: {
    async login(email:string, password:string):Promise<User | null> {
      this.loading = true;
      try {
        if (!email || !password) {
          throw ClientAppError.invalidInput('Email and password are required');
        }
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const { user } = userCredential;

        // Add this check
        if (!user) {
          throw ClientAppError.authError('Authentication failed: No user returned');
        }

        this.isLoggedIn = true;
        this.userEmail = user.email;
        this.userName = user.displayName;

        try {
          const idTokenResult = await user.getIdTokenResult();
          // Add this check
          if (!idTokenResult.claims.role) {
            throw ClientAppError.authError('User role not found in token claims');
          }

          this.userRole = String(idTokenResult.claims.role) as UserRole;
        } catch (error) {
          handleError(error, 'userStore.login.getIdTokenResult');
          await this.logout();
          return null;
        }

        window.localStorage.setItem('user', JSON.stringify(user));
        return user;
      } catch (error) {
        if (error instanceof FirebaseError) {
          const errorMessage = AUTH_ERROR_MESSAGES[error.code] || `Authentication error: ${error.message}`;
          handleError(
            ClientAppError.authError(errorMessage),
            'userStore.login',
          );
        } else if (error instanceof ClientAppError) {
          handleError(
            error,
            'userStore.login',
          );
        } else {
          handleError(
            ClientAppError.fromError(error, 'Login failed'),
            'userStore.login',
          );
        }
        return null;
      } finally {
        this.loading = false;
      }
    },
    async logout():Promise<void> {
      this.loading = true;
      try {
        await auth.signOut();
        this.resetState();
        window.localStorage.removeItem('user');
      } catch (error) {
        handleError(
          error,
          'userStore.logout',
        );
      } finally {
        this.loading = false;
      }
    },
    async checkAuthState():Promise<User | null> {
      this.loading = true;

      try {
        const user = await new Promise<User|null>((resolve) => {
          const unsubscribe = auth.onAuthStateChanged((authUser) => {
            unsubscribe();
            resolve(authUser);
          });
        });

        if (!user) {
          this.resetState();
          window.localStorage.removeItem('user');
          return null;
        }

        // User is signed in.
        this.isLoggedIn = true;
        this.userEmail = user.email;
        this.userName = user.displayName;

        try {
          const idTokenResult = await user.getIdTokenResult();
          const { role } = idTokenResult.claims;

          if (!role) {
            handleError(
              ClientAppError.authError('Role claim not found in user token'),
              'userStore.checkAuthState.roleValidation',
            );
            await this.logout();
            return null;
          }

          this.userRole = String(role) as UserRole; // Assign role if it's set
          window.localStorage.setItem('user', JSON.stringify(user));
          return user;
        } catch (error) {
          handleError(error, 'userStore.checkAuthState');
          await this.logout();
          return null; // Reject the promise if there's an error getting the ID token
        }
      } catch (error) {
        handleError(
          error,
          'userStore.checkAuthState',
        );
        this.resetState();
        window.localStorage.removeItem('user');
        return null;
      } finally {
        this.loading = false;
      }
    },
    resetState(): void {
      this.isLoggedIn = false;
      this.userEmail = null;
      this.userName = null;
      this.userRole = null;
    },

  },
  getters: {
    getUserEmail():string {
      if (this.userEmail === null) throw ClientAppError.authError('User email is null');
      return this.userEmail;
    },
    getUserName():string {
      if (this.userName === null) throw ClientAppError.authError('User name is null');
      return this.userName;
    },
    isAdmin():boolean {
      if (this.userRole === null) throw ClientAppError.authError('User role is null');
      return this.userRole === 'MIRENCO_ADMIN';
    },
  },
});
