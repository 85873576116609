import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isValid, parseISO } from 'date-fns';
import * as Sentry from '@sentry/vue';
import type { AvlEvent } from '../../types/models';
import { convertDateToDateRangeUTC, setTimeToMidnight } from './dateUtils';
import getFirebaseIdToken from './authService';
import {
  ApiRoutes, API_ROUTES, DeviceEventsResponse,
} from '../../server/src/types/apiRoutes';
import type { DeviceDetails } from '../../types/deviceDetails';
import type { CalAmpAvlEvent, SearchResponse, SearchResult } from '../../server/src/calAmpService/calAmpResultsType';
import ClientAppError from './clientAppError';
import { handleApiError } from './errorHandler';

/**
 * Get the id token from the current user and make an authenticated API call
 * to an AxiosResponse object.
 */
async function makeAuthenticatedApiCall<T>(
  options: AxiosRequestConfig,
): Promise<AxiosResponse<T>> {
  const idToken = await getFirebaseIdToken();
  if (!idToken) {
    throw ClientAppError.authError('Failed to get auth token');
  }
  const updatedOptions = {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `${idToken}`,
    },
  };
  return axios.request(updatedOptions);
}

async function fetchDataFromApi(
  deviceId: number,
  date: Date,
  pageNumber: number,
  applicationKey: string = window.applicationKey,
): Promise<{ events: SearchResult; isLastPage: boolean; }> {
  if (!applicationKey) {
    throw ClientAppError.configError('Application key is required');
  }
  const { startDate, endDate } = convertDateToDateRangeUTC(date);

  const options = {
    method: 'GET',
    url: `/api/results/events/${deviceId}/?startDate=${startDate}&endDate=${endDate}&pg=${pageNumber}`,
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
      'calamp-services-app': applicationKey,
    },

  };
  try {
    const response = await makeAuthenticatedApiCall<SearchResponse>(options);

    const events = response.data.response.results;
    // verify events and isLastPage are present in the response
    if (!events) {
      Sentry.captureMessage('Invalid response from API no events or isLastPage');
      throw ClientAppError.fromError(new Error('Invalid response format'), 'Invalid response format', 500);
    }
    let isLastPage = response.data.response.last;
    if (isLastPage === undefined) {
      isLastPage = true;
      Sentry.captureMessage(`isLastPage missing in API response, defaulting to true for ${deviceId} ${date}`);
    }
    return { events, isLastPage };
  } catch (error) {
    return handleApiError(
      error,
      'apiService.fetchDataFromApi',
      `Error fetching data from API for device ${deviceId} on date ${date}`,
    );
  }
}

async function getAllEvents(
  deviceId: number,
  date: string,
  pageNumber: number = 1,
  accumulatedResults: AvlEvent[] = [],
): Promise<AvlEvent[]> {
  try {
    // validate date using date-fns and cast to date object
    if (isValid(new Date(date)) === false) {
      throw new Error('Invalid date format');
    }
    const parsedDate = new Date(date);

    const { events, isLastPage } = await fetchDataFromApi(deviceId, parsedDate, pageNumber);

    const denestedEvents = events.map((event) => event.avlEvent)
      .filter((event): event is CalAmpAvlEvent => event !== undefined);
    // Convert API response into AvlEvent instances
    const avlEvents: AvlEvent[] = denestedEvents.map((event:CalAmpAvlEvent) => ({
      eventType: event.eventType,
      eventTime: new Date(event.eventTime),
      latitude: event.latitude,
      longitude: event.longitude,
      deviceId: event.deviceId,
      messageUuid: event.messageUuid,
      visible: true,
      lmdirectMessageType: event.lmdirectMessageType,
      timeOfFix: new Date(event.timeOfFix),
      messageReceivedTime: new Date(event.messageReceivedTime),
      created: new Date(event.created),
      rawDeviceHexMessage: event.rawDeviceHexMessage,
      eventCode: event.eventCode,
      ignore: false,
      eventid: 1,
    }));

    accumulatedResults.push(...avlEvents);

    if (!isLastPage && pageNumber < 5) {
      return getAllEvents(deviceId, date, pageNumber + 1, accumulatedResults);
    }

    return accumulatedResults;
  } catch (error) {
    return handleApiError(
      error,
      'apiService.getAllEvents',
      `Error fetching all events for device ${deviceId} on date ${date}`,
    );
  }
}

type FuelRegenHour = ApiRoutes[typeof API_ROUTES.FUEL_REGEN]['get']['response'];
async function getFuelRegen(deviceId: number): Promise<FuelRegenHour> {
  try {
    const axiosOptions = {
      method: 'GET',
      url: `/api/fuelregen/${deviceId}`,
    };
    const response = await makeAuthenticatedApiCall<FuelRegenHour>(axiosOptions);
    return response.data;
  } catch (error) {
    return handleApiError(
      error,
      'apiService.getFuelRegen',
      `Error fetching fuel regen data for device ${deviceId}`,
    );
  }
}

const deviceDetails: DeviceDetails[] = [];

async function getDeviceDetails(): Promise<DeviceDetails[]> {
  const options = {
    url: '/api/devices',
    method: 'GET',
  };
  if (deviceDetails.length > 0) return deviceDetails;

  try {
    const response = await makeAuthenticatedApiCall<DeviceDetails[]>(options);
    // convert response.data to deviceDetails
    response.data.forEach((device) => {
      deviceDetails.push({
        calampDeviceId: device.calampDeviceId,
        assetNumber: device.assetNumber,
        serialNumber: device.serialNumber,
        locationName: device.locationName,
        fuelMultiplier: device.fuelMultiplier,
        assetType: device.assetType,
        id: device.id,
      });
    });
    return deviceDetails;
  } catch (error) {
    return handleApiError(
      error,
      'apiService.getDeviceDetails',
      'Error fetching device details',
    );
  }
}

/**
 * Get all events for a device over a 24-hour period starting from the provided date
 * @param deviceId The device ID to fetch events for
 * @param date A date object representing the date to get events for
 * @returns Promise with events and metadata
 */
async function getDeviceEventsByDate(
  deviceId: number,
  date: Date | string,
): Promise<DeviceEventsResponse> {
  try {
    // Convert input to proper Date object at start of day in local timezone
    let dateObj: Date;

    if (typeof date === 'string') {
      // Parse the string as a date, handling both "YYYY-MM-DD" and ISO formats
      dateObj = parseISO(date);
    } else {
      // Use the provided Date object
      dateObj = date;
    }

    // Validate the date
    if (!dateObj || Number.isNaN(dateObj.getTime())) {
      throw new Error('Invalid date parameter');
    }

    // Use setTimeToMidnight to ensure we start at beginning of day in local time
    const startOfDay = setTimeToMidnight(dateObj);

    // Convert to ISO string for the API
    const startTimeIso = startOfDay.toISOString();

    // Prepare the request options
    const options = {
      method: 'GET',
      url: `/api/events/device/${deviceId}/from/${startTimeIso}`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Make the API call
    const response = await makeAuthenticatedApiCall<DeviceEventsResponse>(options);

    // Return the response data
    return response.data;
  } catch (error) {
    return handleApiError(
      error,
      'apiService.getDeviceEventsByDate',
      `Error fetching events for device ${deviceId} on date ${date}`,
    );
  }
}

// Export the function
export { getDeviceEventsByDate };

export {
  getAllEvents, fetchDataFromApi, getFuelRegen, convertDateToDateRangeUTC,
  makeAuthenticatedApiCall, getDeviceDetails,
};
