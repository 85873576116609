import * as Sentry from '@sentry/vue';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { ApiError } from '../../server/src/types/apiRoutes.js';
import AppError from '../../server/src/errorTypes';
import ClientAppError from './clientAppError.js';

export interface ClientError {
  message: string;
  code?: string;
  originalError?: unknown;
}

// Define a type for API error responses
interface ErrorResponseData {
  error?: string;
  message?: string;
  statusCode?: number;
  [key: string]: unknown;
}

/**
 * Handles application errors consistently by:
 * 1. Logging to console
 * 2. Reporting to Sentry
 * 3. Returning a user-friendly error message
 *
 * @param error - The error to handle
 * @param context - Additional context about where the error occurred
 * @returns A sanitized error suitable for displaying to users
 */
function handleError(error: unknown, context: string = 'application'): ClientError {
  // Determine the error type and extract useful information
  let clientError: ClientError;

  // First check if this is an AppError from our server
  if (error instanceof AppError) {
    clientError = {
      message: error.message,
      code: `${error.errorType.toUpperCase()}_${error.statusCode}`,
      originalError: error,
    };
  } else if (error instanceof ApiError) {
    clientError = {
      message: error.message,
      code: error.statusCode ? `HTTP_${error.statusCode}` : undefined,
      originalError: error,
    };
  } else if (error instanceof Error) {
    clientError = {
      message: error.message || 'An unexpected error occurred',
      originalError: error,
    };

    // Handle Axios errors with proper typing
    if ('isAxiosError' in error && error.isAxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        const { status } = axiosError.response;
        clientError.code = `HTTP_${status}`;

        // Try to get structured error data from the response
        const responseData = axiosError.response.data as ErrorResponseData;
        if (responseData && responseData.error) {
          clientError.message = responseData.error;
        } else {
          // Default messages based on status code
          switch (status) {
            case 401:
              clientError.message = 'Please sign in to continue';
              break;
            case 403:
              clientError.message = 'You don\'t have permission to perform this action';
              break;
            case 404:
              clientError.message = 'The requested resource was not found';
              break;
            case 500:
              clientError.message = 'Something went wrong on our servers';
              break;
            default:
              clientError.message = 'An unexpected error occurred';
              break;
          }
        }
      } else if (axiosError.request) {
        // Network error (no response received)
        clientError.message = 'Unable to connect to the server. Please check your internet connection.';
        clientError.code = 'NETWORK_ERROR';
      }
    }
  } else if (typeof error === 'string') {
    // Handle string errors
    clientError = {
      message: error,
    };
  } else {
    // Handle unknown error types
    clientError = {
      message: 'An unknown error occurred',
      originalError: error,
    };
  }

  // Log the error to console
  console.error(`Error in ${context}:`, error);

  // Report to Sentry with additional context
  Sentry.captureException(clientError.originalError || clientError.message, {
    tags: {
      context,
      errorCode: clientError.code || 'UNKNOWN',
    },
    extra: {
      originalError: clientError.originalError,
    },
  });

  return clientError;
}

/**
 * Format error message for display to users
 */
function formatErrorMessage(error: ClientError): string {
  return error.message;
}

/**
 * Handles errors consistently across API calls
 * @param error - The caught error
 * @param context - Context for error reporting
 * @param defaultMessage - Default error message if none available
 * @throws AppError - A standardized error object
 */
function handleApiError(error: unknown, context: string, defaultMessage: string): never {
  // Log the error first
  const clientError = handleError(error, context);

  // Then transform and throw the appropriate error type
  if (axios.isAxiosError(error)) {
    throw ClientAppError.fromAxiosError(error, defaultMessage);
  } else if (error instanceof AppError) {
    throw error; // Already an AppError, just re-throw
  } else {
    throw ClientAppError.fromError(
      error,
      clientError.message || defaultMessage,
      500,
    );
  }
}

export { handleError, formatErrorMessage, handleApiError };
