/**
 *
 * @param {Date} date - The date to set the time to midnight
 * @returns {Date} - The date with the time set to midnight
 */
function setTimeToMidnight(date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 *
 * @param {Date} date - The date to set the time to before midnight
 * @returns {Date} - The date with the time set to before midnight
 */
function setTimeToBeforeMidnight(date) {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
}

/**
 *
 * @param {Date} date - Date to convert
 * @returns {{startDate: string, endDate: string}} - Object with start and end date
 * in ISO 8601 format
 */
function convertDateToDateRangeUTC(date: Date|string): { startDate: string; endDate: string; } {
  // assert valid date
  if (Number.isNaN(Date.parse(date))) {
    throw new Error('Invalid date');
  }
  const startDate = new Date(date);
  // Get timezone offset in minutes and convert it to hours
  const timezoneOffsetHours = startDate.getTimezoneOffset() / 60;
  // Adjust the start date by the timezone offset
  startDate.setUTCHours(timezoneOffsetHours);
  const endDate = new Date(date);
  // Subtract 23 hours, 59 minutes, and 59 seconds
  endDate.setUTCHours(startDate.getUTCHours() + 23);
  endDate.setUTCMinutes(startDate.getUTCMinutes() + 59);
  endDate.setUTCSeconds(startDate.getUTCSeconds() + 59);
  return { startDate: startDate.toISOString(), endDate: endDate.toISOString() };
}

export { setTimeToBeforeMidnight, setTimeToMidnight, convertDateToDateRangeUTC };
