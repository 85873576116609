import { defineStore } from 'pinia';
import { getDeviceDetails } from '../modules/apiService';
import { useUserStore } from './userStore';
import type { DeviceDetails } from '../../types/deviceDetails';
import type { RegenHourFuel as RegenDuration } from '../../types/models';
import { handleError } from '../modules/errorHandler';

/** Define the type for the state */
interface DeviceState {
  devices: DeviceDetails[];
  deviceDetails: Record<number, DeviceDetails>;
  isLoaded: boolean;
}
/**
 * Device store for managing device data.
 */
// eslint-disable-next-line import/prefer-default-export
export const useDeviceStore = defineStore({
  id: 'device',
  state: (): DeviceState => ({
    devices: [],
    deviceDetails: {},
    isLoaded: false,
  }),
  actions: {
    /**
     * Fetches devices and updates the store.
     */
    async fetchDevices(): Promise<void> {
      try {
        const userStore = useUserStore();
        if (!userStore.isLoggedIn) {
          throw new Error('User not logged in');
        }
        this.isLoaded = false;
        const devices = await getDeviceDetails();

        if (!devices || !Array.isArray(devices)) {
          throw new Error('Invalid device data received');
        }
        this.devices = devices;

        this.devices.forEach((device) => {
          this.deviceDetails[device.calampDeviceId] = device;
        });
      } catch (error) {
        handleError(error, 'DeviceStore.fetchDevices');
      } finally {
        this.isLoaded = true;
      }
    },

    /**
     * Process fuel regen data and apply fuel multiplier.
     * @param {RegenDuration[]} regenData - Raw regen data.
     * @param {number} deviceId - The device ID to get fuel multiplier.
     * @returns {RegenDuration[]} Processed regen data with applied fuel multiplier.
     */
    processFuelRegenData(regenData: RegenDuration[], deviceId: number): RegenDuration[] {
      try {
        const device = this.deviceDetails[deviceId];
        if (!device) {
          throw new Error(`Device with ID ${deviceId} not found`);
        }
        const { fuelMultiplier } = device;
        if (fuelMultiplier === undefined || fuelMultiplier === null) {
          throw new Error(`Fuel multiplier not found for device ID ${deviceId}`);
        }
        return regenData.map((regen) => ({
          ...regen,
          fuel_used: regen.fuelEventCount * fuelMultiplier,
        }));
      } catch (error) {
        handleError(error, 'DeviceStore.processFuelRegenData');
        return [];
      }
    },
  },

  getters: {
    /**
     * Gets all devices.
     * @returns {Array<DeviceDetails>} The list of devices.
     */
    getDevices(): DeviceDetails[] {
      return this.devices;
    },
    /**
     * Gets device details by device ID.
     * @returns {(deviceId: string) => DeviceDetails | null} -
     *  A function that returns device details.
     */
    getDeviceDetails: (state:DeviceState) => (deviceId: number) => {
      try {
        const device = state.deviceDetails[deviceId];
        if (!device) {
          throw new Error(`Device with ID ${deviceId} not found`);
        }
        return device;
      } catch (error) {
        handleError(error, 'deviceStore.getDeviceDetails');
        return null;
      }
    },
    /**
     * Gets the fuel multiplier for a device.
        */
    getFuelMultiplier: (state) => (deviceId:number) => {
      try {
        const device = state.deviceDetails[deviceId];
        if (!device) {
          throw new Error(`Device with ID ${deviceId} not found`);
        }
        const { fuelMultiplier } = device;
        if (fuelMultiplier === null || fuelMultiplier === undefined) {
          throw new Error(`Fuel multiplier not available for device ${deviceId}`);
        }

        return fuelMultiplier;
      } catch (error) {
        handleError(error, 'deviceStore.getFuelMultiplier');
        return null;
      }
    },
    /**
     * Gets a list of device IDs.
     */
    getDeviceIdList(state:DeviceState):number[] {
      try {
        if (!state.devices || state.devices.length === 0) {
          throw new Error('No devices found');
        }
        return state.devices.map((device) => device.calampDeviceId);
      } catch (error) {
        handleError(error, 'deviceStore.getDeviceIdList');
        return [];
      }
    },
  },
});
