/* eslint-disable no-param-reassign */
import {
  subWeeks, isWithinInterval, subSeconds, differenceInMilliseconds, differenceInSeconds,
} from 'date-fns';

import eventTypes from '../helpers/eventTypes';
import { AvlEvent, RegenHourFuel } from '../../types/models';

class RegenHour {
  startTimeStamp: Date | null;

  endTimeStamp: Date;

  duration: number;

  constructor(startTimeStamp: Date|null, endTimeStamp: Date, duration: number) {
    // timestamp when the regen hour starts
    this.startTimeStamp = startTimeStamp;
    // timestamp when the regen hour ends
    this.endTimeStamp = endTimeStamp;
    this.duration = duration || 0;
  }
}

/**
 * If there is a regen on, regen on, regen off sequence with less than 1 minute
 * between the regen on events, then swap the regen on and off events and their timestamp
 * TODO: add a modified property to the regen event to indicate that it was modified
 * @param {Array} regenEvents - regen events
 * @returns {Array} regenEvents
 */
function resortOutofOrderRegenEvents(regenEvents:AvlEvent[]) {
  for (let i = 0; i < regenEvents.length; i++) {
    if (i + 3 < regenEvents.length) {
      const condition1 = regenEvents[i].eventType === eventTypes.REGENON;
      const condition2 = regenEvents[i + 1].eventType === eventTypes.REGENON;
      const condition3 = regenEvents[i + 2].eventType === eventTypes.REGENOFF;
      const condition4 = differenceInMilliseconds(
        new Date(regenEvents[i + 1].eventTime),
        new Date(regenEvents[i + 2].eventTime),
      ) < 60000;
      // add condition that checks if swapping i+1 and i+2 doesn't cause order issues

      if (condition1 && condition2 && condition3 && condition4) {
        console.log(`rearranging regen events ${regenEvents[i + 1].eventType} ${regenEvents[i + 1].eventTime} and ${regenEvents[i + 2].eventType} ${regenEvents[i + 2].eventTime}`);
        const tempTimestamp = regenEvents[i + 2].eventTime;
        regenEvents[i + 2].eventTime = regenEvents[i + 1].eventTime;
        regenEvents[i + 1].eventTime = tempTimestamp;

        // const temp = regenEvents[i + 2];
        // regenEvents[i + 2] = regenEvents[i + 1];
        // regenEvents[i + 1] = temp;
      }
    }
  }

  return regenEvents;
}

/**
 *
 * @param {Array} regenEvents - regen events
 * @returns {Array} regenHours
 */
function createRegenBuckets(regenEvents:AvlEvent[]): RegenHour[] {
  const regenHours = [];
  for (let i = 0; i < regenEvents.length; i += 2) {
    if (regenEvents[i].eventType !== eventTypes.REGENOFF) {
      throw new Error(`Invalid event type: expected REGENOFF. Got ${regenEvents[i].eventType} at ${regenEvents[i].eventTime}`);
    }
    if (regenEvents[i + 1].eventType !== eventTypes.REGENON) {
      throw new Error(`Invalid event type: expected REGENON Got ${regenEvents[i].eventType} at ${regenEvents[i].eventTime}`);
    }
    const regenOffEvent = regenEvents[i];
    const regenOnEvent = regenEvents[i + 1];
    const regenHour = new RegenHour(
      regenOnEvent.eventTime,
      regenOffEvent.eventTime,
      differenceInSeconds(regenOffEvent.eventTime, regenOnEvent.eventTime),
    );
    regenHours.push(regenHour);
  }
  return regenHours;
}

/*
  create a new collection
  loop through each regen duration
  keep a running total of the duration
  if the duration is greater than 1 hour
  then subract 1 hour from the total duration in seconds call it remainder

  create a new regen hour object with the end time as the last start time minus one second
  */
/**
 *
 * @param {Array} regenDuration - regen duration
 * @returns {Array} regenHourChunks
 */
function createRegenHourChunks(regenDuration:RegenHour[]) {
  const regenHourChunks = [];
  let totalDuration = 0;

  // add the end time of the first regen duration as end time of regen hour
  regenHourChunks.push(new RegenHour(
    null,
    new Date(regenDuration[0].endTimeStamp),
    0,
  ));
  for (let i = 0; i < regenDuration.length; i++) {
    const currentRegenDuration = regenDuration[i];

    // check if start time is null
    if (currentRegenDuration.startTimeStamp === null) {
      throw new Error('Start time is null');
    }
    const durationInSeconds = differenceInSeconds(
      new Date(currentRegenDuration.endTimeStamp),
      new Date(currentRegenDuration.startTimeStamp),
    );
    totalDuration += durationInSeconds;
    if (totalDuration >= 3600) {
      const remainder = totalDuration - 3600;
      //  subract the remainder from the end time of the current regen duration
      // add this as the value of the start time of the last regen hour
      const currentRegenHourStartTime = subSeconds(new Date(
        currentRegenDuration.endTimeStamp,
      ), 3600);
      regenHourChunks[regenHourChunks.length - 1].startTimeStamp = currentRegenHourStartTime;
      regenHourChunks[regenHourChunks.length - 1].duration = 3600;
      const nextRegenHourEndTime = subSeconds(currentRegenHourStartTime, 1);
      regenHourChunks.push(new RegenHour(
        null,
        nextRegenHourEndTime,
        0,
      ));
      totalDuration = remainder;
    }
    if (i === regenDuration.length - 1) {
      // eslint-disable-next-line max-len
      regenHourChunks[regenHourChunks.length - 1].startTimeStamp = new Date(currentRegenDuration.startTimeStamp);
      regenHourChunks[regenHourChunks.length - 1].duration = totalDuration;
    }
  }

  return regenHourChunks;
}

/**
 *
 * @param {Array} sortedAvlEvents - sorted AVL events
 * @returns {Array} sortedAvlEvents
 */
function filterLatestEvents(sortedAvlEvents:AvlEvent[]) {
  let foundRegenOff = false;
  for (let i = 0; i < sortedAvlEvents.length; i++) {
    const event = sortedAvlEvents[i];
    if (event.eventType === eventTypes.REGENOFF) {
      foundRegenOff = true;
      return sortedAvlEvents.slice(i);
    }
  }
  return foundRegenOff ? [] : sortedAvlEvents;
}

/**
 * Filter out mismatched events
 * @param {AvlEvent} event - AVL event
 * @param {number} index - index of the event
 * @param {AvlEvent[]} events - AVL events
 * @returns {boolean} keepEvent
 */
function filterMismatchedEvents(event:AvlEvent, index:number, events:AvlEvent[]) {
  if (index === events.length - 1 && event.eventType === eventTypes.REGENOFF) {
    return false;
  }
  if (index === events.length - 1) {
    // Keep the last event
    return true;
  }

  if (event.eventType === eventTypes.REGENON
      && events[index + 1].eventType === eventTypes.REGENON) {
    console.log(`skipping regen on ${event.eventTime}}`);
    // Skip this event if it's a regen on followed by another regen on
    return false;
  }

  if (event.eventType === eventTypes.REGENOFF
      && events[index + 1].eventType === eventTypes.REGENOFF) {
    // Skip this event if it's a regen off followed by another regen off
    console.log(`skipping regen on ${event.eventTime}}`);
    return false;
  }
  // Keep all other events
  return true;
}

/**
 *
 * @param {AvlEvent[]} avlEvents - AVL events
 * @returns {number} totalDurationInSec
 * @throws {Error} Error calculating total regen duration
 */
function regenTotalDuration(avlEvents:AvlEvent[]) {
  const regenEvents = avlEvents.filter(
    (avlEvent) => avlEvent.eventType === eventTypes.REGENON
    || avlEvent.eventType === eventTypes.REGENOFF,
  );
  const sortedRegenEvents = regenEvents.sort(
    (a, b) => new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime(),
  );

  const sortOutofOrderRegenEvents = resortOutofOrderRegenEvents(sortedRegenEvents)
    .sort((a, b) => new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime())
    .filter(filterMismatchedEvents);
  const filteredSortedRegenEvents = filterLatestEvents(sortOutofOrderRegenEvents);

  // handle error
  try {
    const regenBuckets = createRegenBuckets(filteredSortedRegenEvents);
    // reduce regen buckets to total duration
    const totalDurationInSec = regenBuckets.reduce((total, regenHour) => {
      total += regenHour.duration;
      return total;
    }, 0);
    return totalDurationInSec;
  } catch (error) {
    throw new Error('Error calculating total regen duration');
  }
}

/**
 * @param {Date} date - The date from which to calculate the average regen duration.
 * @param {RegenHourFuel[]} regenHourFuel - An array of RegenDuration objects.
 * @returns {number} The average regen duration for the last two weeks from the given date.
 */
function calculateAverageRegenDuration(date:Date, regenHourFuel: RegenHourFuel[]) {
  // Calculate the start and end dates for the two week period
  const endDate = date;
  const startDate = subWeeks(endDate, 2);

  // Filter the data to only include events within the last two weeks
  const lastTwoWeeksData = regenHourFuel.filter(
    (event) => isWithinInterval(
      new Date(event.regenHourStart),
      { start: startDate, end: endDate },
    ),
  );

  // Calculate the sum of the fuel_event_count for these events
  const sum = lastTwoWeeksData.reduce((total, event) => total + event.fuelEventCount, 0);

  // Calculate the average
  const average = sum / lastTwoWeeksData.length;

  return average;
}

export {
  filterMismatchedEvents,
  filterLatestEvents,
  createRegenBuckets,
  createRegenHourChunks,
  resortOutofOrderRegenEvents,
  regenTotalDuration,
  RegenHour,
  calculateAverageRegenDuration,
};
