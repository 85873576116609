// src/store/eventsStore.js
import { defineStore } from 'pinia';
import { formatDuration, format } from 'date-fns';
import { ApiError } from 'server/src/types/apiRoutes';
import { getAllEvents, getFuelRegen } from '../modules/apiService';
import AVL_EVENT_TYPES from '../helpers/eventTypes';
import { regenTotalDuration } from '../modules/fuelRegenCalc';
import { useDeviceStore } from './deviceStore';
import processOutOfRegen from '../modules/eventStreamCorrection';
import { handleError } from '../modules/errorHandler';

import type { AvlEvent, RegenHourFuel as RegenDuration, SummaryItems } from '../../types/models';

function eventTypeCounter(events: AvlEvent[]): SummaryItems {
  const eventTypeCounts: Record<string, number> = {
    'Ignition On': 0,
    'Ignition Off': 0,
    'Fuel On': 0,
    'ReGen On': 0,
    'ReGen Off': 0,
  };

  events.forEach((event) => {
    const { eventType } = event;
    if (eventTypeCounts[eventType]) {
      eventTypeCounts[eventType] += 1;
    } else {
      eventTypeCounts[eventType] = 1;
    }
  });

  return eventTypeCounts;
}

/**
 * Structure of store's state.
 */
type EventsState = {
  events: AvlEvent[];
  regenHours: RegenDuration[];
  selectedDeviceId: number | null;
  selectedDate: Date | null;
  summaryData: SummaryItems;
  isLoaded: boolean;
  hasData: boolean;
  isLoading: boolean;
  isFuelRegenLoading: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const useEventsStore = defineStore({
  id: 'events',
  state: (): EventsState => ({
    /** @type {AvlEvent[]} */
    events: [],
    /** @type {RegenDuration[]} */
    regenHours: [],
    selectedDeviceId: null,
    selectedDate: null,
    // @type {SummaryItems}
    summaryData: {},
    isLoaded: false,
    hasData: false,
    isLoading: false,
    isFuelRegenLoading: false,
  }),
  actions: {
    /**
     * Fetches events for the selected device and date.
     * @returns {Promise<void>} - A promise that resolves when the events are fetched.
     */
    async fetchEvents(): Promise<void> {
      this.isLoaded = false;
      this.isLoading = true;
      this.hasData = false;
      try {
        if (this.selectedDate === null || this.selectedDeviceId === null) {
          throw new Error('Device ID and Date are required');
        }
        const [response] = await Promise.all([
          getAllEvents(this.selectedDeviceId, this.getFormattedSelectedDate),
          this.fetchRegenHours(this.selectedDeviceId),
        ]);

        // const eventsDb = await getDeviceEventsByDate(
        //   this.selectedDeviceId,
        //   this.getFormattedSelectedDate,
        // );
        // console.log('eventsDb length', eventsDb.events.length,
        // 'api events length', response.length);

        const filteredEvents = response.filter(
          (event) => Object.values(AVL_EVENT_TYPES).includes(event.eventType),
        );
        this.events = processOutOfRegen(filteredEvents);

        if (this.events.length > 0) {
          this.hasData = true;
        }
      } catch (error) {
        handleError(error, 'eventsStore.fetchEvents');
      } finally {
        this.isLoaded = true;
        this.isLoading = false;
      }
    },
    /**
     *
     * @param {number} deviceId - Device ID
     * @returns {Promise<void>}
     */
    async fetchRegenHours(deviceId: number): Promise<void> {
      this.isFuelRegenLoading = true;
      try {
        const deviceStore:ReturnType<typeof useDeviceStore> = useDeviceStore();

        const response = await getFuelRegen(deviceId);
        if (response instanceof ApiError) {
          throw response;
        }
        const fuelMultiplier = deviceStore.getFuelMultiplier(deviceId);
        if (fuelMultiplier === null) {
          throw new Error(`No valid Fuel Multiplier found for ${deviceId}`);
        }
        response.forEach((regenStat) => {
          // eslint-disable-next-line no-param-reassign
          regenStat.fuelUsed = regenStat.fuelEventCount * fuelMultiplier;
        });

        this.regenHours = response;
      } catch (error) {
        handleError(error, 'eventsStore.fetchRegenHours');
      } finally {
        this.isFuelRegenLoading = false;
      }
    },
    /**
     *
     * @param {number} deviceId - Device ID
     */
    setSelectedDeviceId(deviceId:number):void {
      this.selectedDeviceId = deviceId;
      // this.fetchRegenHours(deviceId);
    },
    /**
     * @param {string | Date} date - Date string or Date object
     */
    setSelectedDate(date:string|Date):void {
      // if date is a string and valid Date
      if (typeof date === 'string' && new Date(date).toString() !== 'Invalid Date') {
        const [year, month, day] = date.split('-').map(Number);
        this.selectedDate = new Date(year, month - 1, day);
        this.selectedDate.setHours(0, 0, 0, 0);
      } else if (date instanceof Date) {
        this.selectedDate = date;
      } else {
        throw new Error('Invalid Date');
      }
    },
  },
  getters: {
    getEvents():AvlEvent[] {
      return this.events;
    },
    fuelEvents(state):AvlEvent[] {
      return state.events.filter((event) => event.eventType === AVL_EVENT_TYPES.FUELON);
    },
    fuelMultiplier(): number {
      const deviceStore = useDeviceStore();

      // get current device id and get device details of that device from device store
      // return the fuel multiplier from the device details
      if (this.selectedDeviceId === null) throw new Error('Selected Device ID is required');
      const fuelMultiplier = deviceStore.getFuelMultiplier(this.selectedDeviceId);
      if (fuelMultiplier === null) throw new Error('Fuel Multiplier not found');

      return fuelMultiplier;
    },
    getEventsSummary(): SummaryItems {
      const summaryData = eventTypeCounter(this.events);
      // handle error thrown by regenTotalDuration
      let regenDurationFormatted = 'N/A';
      try {
        const regenDuration = regenTotalDuration(this.events);

        const duration = {
          hours: Math.floor(regenDuration / 3600),
          minutes: Math.floor((regenDuration % 3600) / 60),
        };
        regenDurationFormatted = formatDuration(duration);
      } catch (error) {
        handleError(error, 'eventsStore.getEventsSummary');
      }

      try {
        summaryData['Fuel Used'] = `${Number(summaryData['Fuel On']) * Number(this.fuelMultiplier)} gals`;
        summaryData['Regen Duration'] = regenDurationFormatted;
        delete summaryData['Fuel On'];
      } catch (error) {
        handleError(error, 'eventsStore.getEventsSummary');
      }
      return summaryData;
    },
    getSelectedDate(): Date {
      if (this.selectedDate === null) throw new Error('Selected Date is required');
      // create a new date with the date from the field and set the time to 00:00:00
      return new Date(this.selectedDate);
    },

    getFormattedSelectedDate(): string {
      if (this.selectedDate === null) throw new Error('Selected Date is required');

      return format(this.selectedDate, 'yyyy-MM-dd');
    },
    getRegenHoursForSelectedDate():RegenDuration[] {
      if (this.selectedDate === null) throw new Error('Selected Date is required');
      if (this.regenHours.length === 0) {
        handleError(
          {
            message:
        `No regen hours found for ${this.selectedDeviceId} on ${this.selectedDate}`,
          },
          'eventStore.getRegenHoursForSelectedDate',
        );
        return [];
      }

      const selectedDateStart = new Date(this.selectedDate);
      selectedDateStart.setHours(0, 0, 0, 0);
      const selectedDateEnd = new Date(this.selectedDate);
      selectedDateEnd.setHours(23, 59, 59, 999);
      const regenHoursForSelectedDate = this.regenHours.filter(
        /**
         * @returns {boolean} - If the regen hour is within the selected date
         */
        (regen:RegenDuration):boolean => {
          const regenEnd = new Date(regen.regenHourEnd);
          return regenEnd >= selectedDateStart && regenEnd <= selectedDateEnd;
        },
      );
      return regenHoursForSelectedDate;
    },
  },
});
