<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useUserStore } from './store/userStore';
import { useDeviceStore } from './store/deviceStore';

export default {
  name: 'App',
  components: {
  },
  setup() {
    const userStore = useUserStore();
    const deviceStore = useDeviceStore();

    const appReady = computed(() => userStore.isLoggedIn && deviceStore.isLoaded);
    onMounted(async () => {
      //      await deviceStore.fetchDevices();
    });
    return {
      appReady,
    };
  },
};
</script>
