import type { AxiosError } from 'axios';
import AppError from '../../server/src/errorTypes';

interface ErrorResponseData {
  message?: string;
  error?: string;
  [key: string]: unknown;
}

/**
 * Creates AppError objects for client-side use
 */
class ClientAppError {
  /**
   * Creates an error for network connectivity issues
   * @param message - Error message
   * @param details - Additional error details
   */
  static networkError(details?: unknown, message: string = 'Network connection error'): AppError {
    return new AppError(message, 503, 'network_error', details);
  }

  /**
   * Creates an error for configuration issues
   * @param message - Error message
   * @param details - Additional error details
   */
  static configError(message: string, details?: unknown): AppError {
    return new AppError(message, 500, 'config_error', details);
  }

  /**
   * Creates an error for authentication issues
   * @param message - Error message
   * @param details - Additional error details
   */
  static authError(details?: unknown, message: string = 'Authentication failed'): AppError {
    return new AppError(message, 401, 'auth_error', details);
  }

  /**
   * Creates an error for invalid input
   * @param message - Error message
   * @param details - Additional error details
   */
  static invalidInput(message: string, details?: unknown): AppError {
    return new AppError(message, 400, 'validation', details);
  }

  /**
 * Creates an error for UI/component rendering issues
 * @param message - Error message
 * @param details - Additional error details
 */
  static renderingError(message: string, details?: unknown): AppError {
    return new AppError(message, 500, 'rendering_error', details);
  }

  /**
   * Creates an error from an axios error
   * @param error - The original Axios error
   * @param defaultMessage - Default message if none available in error
   */
  static fromAxiosError(error: AxiosError, defaultMessage?: string): AppError {
    const status = error.response?.status || 500;

    // Cast response data to a typed interface to fix the TypeScript error
    const responseData = error.response?.data as ErrorResponseData | undefined;
    const message = responseData?.message || error.message || defaultMessage || 'API request failed';

    const details = {
      config: error.config,
      data: error.response?.data,
    };

    return new AppError(message, status, 'api_error', details);
  }

  /**
   * Creates an error from any type of error
   * @param error - The original error
   * @param defaultMessage - Default message if none available in error
   * @param defaultStatus - Default status code
   */
  static fromError(error: unknown, defaultMessage: string = 'An error occurred', defaultStatus: number = 500): AppError {
    // If it's already an AppError, return it
    if (error instanceof AppError) {
      return error;
    }

    // If it's an Error, use its message
    if (error instanceof Error) {
      return new AppError(error.message || defaultMessage, defaultStatus, 'client_error', error);
    }

    // For unknown errors
    return new AppError(defaultMessage, defaultStatus, 'unknown_error', error);
  }
}

// Export the class directly
export default ClientAppError;
