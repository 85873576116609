export default class AppError extends Error {
  constructor(
    message: string,
      public statusCode: number = 500,
      public errorType: string = 'general',
      public details?: unknown,
  ) {
    super(message);
    this.name = 'AppError';
    Error.captureStackTrace(this, this.constructor);
  }

  static notFound(resource: string): AppError {
    return new AppError(`Resource not found: ${resource}`, 404, 'not_found');
  }

  static validation(message: string, details?: unknown): AppError {
    return new AppError(message, 400, 'validation', details);
  }

  static unauthorized(message: string = 'Authentication required'): AppError {
    return new AppError(message, 401, 'unauthorized');
  }

  static forbidden(message: string = 'Access denied'): AppError {
    return new AppError(message, 403, 'forbidden');
  }

  static apiRequest(message: string, details?: unknown, statusCode: number = 500): AppError {
    return new AppError(message, statusCode, 'api_request', details);
  }

  static internalServerError(message: string, details?: unknown): AppError {
    return new AppError(message, 500, 'internal_server_error', details);
  }
}
