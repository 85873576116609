import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from './router';
import App from './App.vue';

// Ensure Sentry is loaded (from HTML script tag)
declare global {
  interface Window {
    Sentry?: typeof import('@sentry/browser');
  }
}

document.addEventListener('DOMContentLoaded', async () => {
  const pinia = createPinia();
  const app = createApp(App);

  if (window.Sentry) {
    app.config.errorHandler = (err: unknown) => {
      window.Sentry?.captureException(err instanceof Error ? err : new Error(String(err)));
    };
    // ✅ Capture uncaught JavaScript errors
    window.onerror = (message, source, lineno, colno, error) => {
      window.Sentry?.captureException(error || new Error(String(message)));
    };

    // ✅ Capture unhandled promise rejections
    window.onunhandledrejection = (event) => {
      window.Sentry?.captureException(
        event.reason instanceof Error ? event.reason : new Error(String(event.reason)),
      );
    };
  }

  app.use(pinia);
  app.use(router);
  app.mount('#app');
});
