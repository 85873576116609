import { differenceInSeconds } from 'date-fns';
import eventTypes from '../helpers/eventTypes';

/**
 * @typedef {import("../../types/models").AvlEvent} AvlEvent
 * @param {AvlEvent[]} events - avlEvents newest to oldest
 * @returns {AvlEvent[]} - sorted events
 */
export default function processOutOfRegen(events) {
  let lastRegenOn = null;

  /**
   * Process from newest to oldest
   * If current event is regen off and last regen event was regen on
   * and its time stamp was less than a second ago swap them
   * Else if the event type if regen on - set last regen on
   */
  events.forEach((event) => {
    if (event.eventType === eventTypes.REGENON) {
      // If Regen On event, update lastRegenOn timestamp
      lastRegenOn = event;
    } else if (event.eventType === eventTypes.REGENOFF) {
      // If there is a previous Regen On event
      if (lastRegenOn) {
        const diffMilliseconds = differenceInSeconds(lastRegenOn.eventTime, event.eventTime);

        if (diffMilliseconds <= 10) {
          // Swap events
          const tempTime = event.eventTime;
          // eslint-disable-next-line no-param-reassign
          event.eventTime = lastRegenOn.eventTime;
          lastRegenOn.eventTime = tempTime;
          console.log('Swapping timestamps');
        }
      }
    }
  });
  // resort the array based on timestamp after swapping oldest to newest
  events.sort((a, b) => b.eventTime.getTime() - a.eventTime.getTime());

  return events;
}
