import { getIdToken, getAuth } from 'firebase/auth';

export default async function getFirebaseIdToken() {
  try {
    const auth = getAuth();
    if (!auth.currentUser) throw new Error('User is not authenticated');
    const { currentUser } = auth;
    const idToken = await getIdToken(currentUser);
    return idToken;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}
