// src/router.js
import {
  RouteLocationNormalized, NavigationGuardNext,
  createRouter, createWebHistory,
} from 'vue-router';
import { sub, format } from 'date-fns';
import { useEventsStore } from './store/eventStore';
import { useUserStore } from './store/userStore';
import { useDeviceStore } from './store/deviceStore';

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/devices',
    meta: { requiresAuth: true },
  },
  {
    path: '/devices',
    name: 'Devices',
    component: () => import('./views/DevicesView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/device/:deviceId/:date?',
    name: 'DayView',
    component: () => import('./views/DayView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (
      to:RouteLocationNormalized,
      from:RouteLocationNormalized,
      next:NavigationGuardNext,
    ) => {
      const eventsStore = useEventsStore();

      // If no date is passed, set a default date
      let date: string;

      if (!to.params.date) {
        const defaultDate = sub(new Date(), { days: 1 });
        date = format(defaultDate, 'yyyy-MM-dd');
      } else {
        // Handle the string or string[] case
        date = Array.isArray(to.params.date) ? to.params.date[0] : to.params.date;
      }

      eventsStore.setSelectedDate(date);
      eventsStore.setSelectedDeviceId(Number(to.params.deviceId));
      if (!to.params.date) {
        next({ name: 'DayView', params: { deviceId: to.params.deviceId, date } });
      } else {
        next();
      }
    },
  },
  {
    path: '/fuelregen/:deviceId?',
    name: 'FuelRegen',
    component: () => import('./views/FuelRegenView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (
      to:RouteLocationNormalized,
      from:RouteLocationNormalized,
      next:NavigationGuardNext,
    ) => {
      const eventsStore = useEventsStore();

      eventsStore.setSelectedDeviceId(Number(to.params.deviceId));

      next();
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('./views/LoginView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const deviceStore = useDeviceStore();

  try {
    const user = await userStore.checkAuthState();
    if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
      // Redirect to login page if not authenticated
      next({ name: 'Login' });
    } else {
      // Proceed as normal if the auth condition is met or not needed
      if (!deviceStore.isLoaded) {
        // If not loaded, fetch devices and wait for it to finish
        await deviceStore.fetchDevices();
      }
      next();
    }
  } catch (error) {
    console.error('Error during route guard auth check:', error);
    next({ name: 'error' }); // Redirect to an error page or similar
  }
});
export default router;
