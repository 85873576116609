// packages/api-types/src/responses.ts
import { DbEvent } from '../dataLayer/dbTypes.js';
import { DeviceDetails, RegenHourFuel } from './models.js';

export const API_ROUTES = {
  FUEL_REGEN: '/api/fuelregen/:deviceid',
  DEVICES: '/api/devices',
  DEVICE_EVENTS: '/api/events/device/:deviceId/from/:startTime',
} as const;

export interface ApiResponse<T> {
  data: T;
  status: number;
}

export class ApiError extends Error {
  constructor(public message: string, public statusCode?: number) {
    super(message);
    this.name = 'ApiError';
  }
}

export interface DeviceEventsResponse {
  deviceId: number;
  startTime: string;
  endTime: string;
  events: DbEvent[];
  totalCount: number | null;
  isTruncated: boolean;
  message?: string;
}

export interface ApiRoutes {
    [API_ROUTES.FUEL_REGEN]: {
    get: {
      params: { deviceid: number };
      response: RegenHourFuel[] | ApiError;
    };
  },
  [API_ROUTES.DEVICES]: {
    get: {
      response: DeviceDetails[] | ApiError;
    };
  },
  [API_ROUTES.DEVICE_EVENTS]:{
    get:{
      params: {
        deviceId: number;
        startTime: string
      };
      response: DeviceEventsResponse | ApiError

    }
  }
}
